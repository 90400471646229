import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Button,
  DialogActions,
  Slide,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// DialogTemplate.defaultProps = {
//   title: "Warning",
//   subtitle: "This is dangeourus action",
//   children:
//     "You are about to do something potentialy dangerous. <br/>Are you shure?",
// };

const DialogTemplate = (props) => {
  const {
    title = "Warning",
    subtitle = "This is dangeourus action",
    children = "You are about to do something potentialy dangerous. <br/>Are you shure?",
    isOpen,
    handleClose,
  } = props;

  const handleConfirmCancel = () => {
    handleClose("CANCEL");
  };

  const handleConfirmOk = () => {
    handleClose("OK");
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography variant="h4" color="warning.main">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText> {subtitle} </DialogContentText>
        <Divider sx={{ my: 2 }} />
        {<div dangerouslySetInnerHTML={{ __html: children }} />}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose} color="primary" variant="outlined"> */}
        <Button
          onClick={handleConfirmCancel}
          color="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button onClick={handleConfirmOk} color="warning" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogTemplate;
