export const lightTheme = {
  palette: {
    mode: "light",
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
};

export const darkTheme = {
  palette: {
    mode: "dark",
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
};

export const initUsersFormValues = {
  id: 0,
  name: "",
  email: "",
  role: "",
  action: "",
  caption: "",
  button: "",
};

export const initClientsFormValues = {
  id: 0,
  name: "",
  email: "",
  address: "",
  mobile: "",
  acronym: "",
  active: "",
  action: "",
  caption: "",
  button: "",
};

export const initCoordinatorsFormValues = {
  id: 0,
  name: "",
  email: "",
  mobile: "",
  active: "",
  action: "",
  caption: "",
  button: "",
};

export const initWorkersFormValues = {
  id: 0,
  name: "",
  email: "",
  mobile: "",
  active: "",
  action: "",
  caption: "",
  button: "",
};

export const initLogBooksFormValues = {
  id: 0,
  customer_id: 0,
  coordinator_id: 0,
  worker_id: 0,
  date: "",
  descript: "",
  note: "",
  action: "",
  caption: "",
  button: "",
};

export const initEarningsFormValues = {
  id: 0,
  date: "",
  worker_id: 0,
  earning_type: 0,
  gain: 0,
  reduce: 0,
  note: "",
  action: "",
  caption: "",
  button: "",
};
