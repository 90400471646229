const HighLight = ({ text, highlight }) => {

  // solve the problem with disappearing space
  var correctedText = text.replace(' ', '\xa0')

  const parts = correctedText.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <>
      {parts.map((part, i) => (
        <span
          key={i}
          style={{
            backgroundColor: part.toLowerCase() === highlight.toLowerCase() ? 'orange' : 'inherit',
            fontWeight: part.toLowerCase() === highlight.toLowerCase() ? 'bold' : 'normal',
            color: part.toLowerCase() === highlight.toLowerCase() ? 'black' : 'normal',
          }}>
          {part}
        </span>
      ))}
    </>
  );
};

export default HighLight;


/* use
  <HighLight text="React is great" highlight="re" /> 
*/
