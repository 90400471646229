import { observer } from "mobx-react-lite";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BarChartTemplate = observer(({ data }) => {
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data.chartData ?? []}
          margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={data.dataKey} />
          <YAxis />
          <Tooltip wrapperStyle={{ color: "#666", opacity: 0.85 }} />
          <Legend horizontalAlign="right" wrapperStyle={{ lineWidth: '40px' }} />
          {(data.chartNames ?? []).map((name, index) => {
            return (
              <Bar key={index} dataKey={name.dataKey} fill={name.fill} />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
});

export default BarChartTemplate;