import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import { observer } from "mobx-react-lite";
import { useStore } from "../store/Store";

import { LoginForm } from "../components/login/LoginForm";

const Login = observer(() => {
  const store = useStore();

  const picPiramida = {
    backgroundImage: `url(${store.piramideFull})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: (t) =>
      t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "all 0.8s",
    transitionTimingFunction: "ease-in-out",
  };

  const responsiveFull = {
    height: "90vh",
    display: "flex",
    flexWrap: "wrap",
  };

  const responsiveLeft = {
    flex: { xs: 0, sm: 5, md: 7, lg: 9 },
  };

  const responsiveRight = {
    flex: { xs: 12, sm: 7, md: 5, lg: 3 },
    px: { xs: 0, sm: 1, md: 2 },
    minWidth: "360px",
  };

  return (
    <Grid container sx={responsiveFull}>
      <Grid item sx={{ ...responsiveLeft, ...picPiramida }} />
      <Grid item sx={responsiveRight} component={Paper} elevation={6}>
        <LoginForm />
      </Grid>
    </Grid>
  );
});

export default Login;

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px
