import { createContext, useContext } from "react";
//import { makeAutoObservable, autorun, runInAction } from "mobx";
import { autorun, makeAutoObservable } from "mobx";
import sha256 from "crypto-js/sha256";

import daySmall from "../assets/giza-day-small.jpg";
import nightSmall from "../assets/giza-night-small.jpg";
import dayFull from "../assets/giza-day.jpg";
import nightFull from "../assets/giza-night.jpg";

class RootStore {
  user = {
    username: "user name",
    email: "email",
    role: "",
    token: "",
    announceText: ""
  };

  isLogged = false;
  isDarkTheme = false;

  piramideSmall = daySmall;
  piramideFull = dayFull;

  monthYear = "";
  loading = false;

  constructor() {
    makeAutoObservable(this);

    // autorun(() => console.log('autorun: ', this.user));
    // runInAction(this.prefetchData);
    // runInAction(() => console.log('runInAction() : ', this.user.username));

    autorun(() => {
      this.piramideFull = this.isDarkTheme ? nightFull : dayFull;
      this.piramideSmall = this.isDarkTheme ? daySmall : nightSmall;
    });
  }

  prefetchData = () => {
    this.updateUsername(process.env.REACT_APP_USERNAME);
  };

  toggleTheme = () => {
    this.isDarkTheme = !this.isDarkTheme;
    if (this.isDarkTheme) {
      this.piramideSmall = daySmall;
      this.piramideFull = nightFull;
    } else {
      this.piramideSmall = nightSmall;
      this.piramideFull = dayFull;
    }
  };

  updateUsername(item) {
    this.user.username = item;
  }
  updateEmail(item) {
    this.user.email = item;
  }
  updateSha256(item) {
    const hash = sha256(`${item}`).toString();
    this.user.sha256 = hash;
    // console.log(item)
    // console.log(hash)
  }
  updateIsLogged(item) {
    this.isLogged = item;
    // console.log("logged: ", this.isLogged);
  }
  updatemonthYear(item) {
    this.monthYear = item;
  }
  updateLoading(item) {
    this.loading = item;
  }
}

const StoreContext = createContext(new RootStore());

function StoreProvider({ store, children }) {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}

const useStore = () => {
  return useContext(StoreContext);
};

export { RootStore, StoreProvider, useStore };
