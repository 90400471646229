import { Container } from "@mui/material";
import { observer } from "mobx-react-lite";
import HighLight from "../components/HighLight";

const Contact = observer(() => {
  return (
    <Container
      maxWidth={false}
      sx={{
        height: "400px",
        // bgcolor: "yellow",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h2>Contact Us Page</h2>
      <h4><HighLight text="React is great react" highlight="re" /></h4>
    </Container>
  );
});

export default Contact;
